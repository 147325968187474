import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'antd'
import cn from 'classnames'
import MainLayout from '../../../../components/HOC/MainLayout/MainLayout'
import { toast } from '../../../../components/Toast/Toast.js'
import { useDispatch } from 'react-redux'
import InputField from '../../../../components/common/InputField/InputField'
import { useHistory } from 'react-router'
import { ReactComponent as IconBack } from '../../../../Assets/image/svgimages/icon_back.svg'
import { Form } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { Bottles, WinerMakerAction } from '../../../../redux/_actions'
import { editBottleSchema } from '../schema'
import BottleLogs from './BottleLogs'
import 'antd/dist/antd.min.css'
import '../../CreateWinemakers/CreateWinemakers.scss'
import '../CreateBottle/CreateBottle.scss'
import './EditBottle.scss'
import { IconDelete } from '../../../../Assets/image/svgimages/sVGImg'
import RadioButton from '../../../../components/common/RadioButton/RadioButton'
import WineBottleName from '../components/WineBottleName'
import Notes from '../components/Notes'
import CardIframe from '../../../../components/common/CardIframe'
import getWineConsist from '../getWineConsist'

const setLogItemValue = (id, arr = [], key = null, fn = () => {}) => {
  if (!id) return
  const value = arr.find((el) => `${el.id}` === `${id}`)?.[key]
  if (!value) return
  fn(value)
}

const EditBottle = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [logsData, setLogsData] = useState({})
  const [wineryList, setWineryList] = useState([])
  const [colorList, setColorList] = useState([])
  const [allCountry, setAllCountry] = useState([])
  const [sizeList, setSizeList] = useState([])
  const [regionList, setRegionList] = useState([])
  const [sweetNessList, setSweetNessList] = useState([])
  const [stillList, setStillList] = useState([])
  const [sparklingList, setSparklingList] = useState([])

  const [appellation, setAppellation] = useState([])
  const [appellationWithRegion, setAppellationWithRegion] = useState([])

  const [countryWithRegion, setCountryWithRegion] = useState([])
  const [bottleDetail, setBottleDetail] = useState({})
  const [sizeText, setSizeText] = useState('') //for preview bottle
  const [sizeTextOld, setSizeTextOld] = useState('') //for preview bottle
  const [badgeText, setBadgeText] = useState('') //for preview bottle
  const [badgeTextOld, setBadgeTextOld] = useState('') //for preview bottle
  const [regionTextOld, setRegionTextOld] = useState('') //for preview bottle
  const [regionText, setRegionText] = useState('') //for preview bottle
  const [appellationOldText, setAppellationTextOld] = useState('') //for preview bottle
  const [appellationNewText, setAppellationNewText] = useState('')
  const [colorTextOld, setColorTextOld] = useState('')
  const [colorTextNew, setColorTextNew] = useState('')

  const [showBottom, setShowBottom] = useState(true)
  const [isActive, setActive] = useState(false)

  const inputRefBottle = React.useRef()

  const { uploadFileGc, saveProfileOldData, getPublicDetailsLogs } = WinerMakerAction
  const {
    getBottleDropDownSpecifications,
    getBottleDetail,
    updateBottle,
    getAllWineryListForDropDown,
  } = Bottles

  useEffect(() => {
    const body = document.querySelector('body')
    document.body.classList.add('bg-white')
    return () => {
      body.classList.remove('bg-white')
    }
  })

  const bottleId = history?.location?.state?.bottle_id
  const userId = history?.location?.state?.id

  useEffect(() => {
    if (!userId) {
      history.push('/panel/bottles')
    }
  }, [userId])

  useEffect(() => {
    // Set old values for logs
    //for size
    setLogItemValue(logsData.bottle?.oldValues?.size_id, sizeList, 'size', setSizeTextOld)

    //for badge
    setLogItemValue(
      logsData.bottle?.oldValues?.badge,
      badgeSelectList.map((el) => ({ id: el, badge: el })),
      'badge',
      setBadgeTextOld,
    )
    setLogItemValue(
      logsData.bottle?.newValues?.badge,
      badgeSelectList.map((el) => ({ id: el, badge: el })),
      'badge',
      setBadgeText,
    )

    //for region for old
    setLogItemValue(
      logsData.bottle?.newValues?.region_id,
      countryWithRegion[logsData.bottle?.newValues?.country || bottleDetail?.country],
      'region_name',
      setRegionTextOld,
    )

    //for latest region
    setLogItemValue(
      logsData.bottle?.oldValues?.region_id,
      countryWithRegion[logsData.bottle?.oldValues?.country || bottleDetail?.country],
      'region_name',
      setRegionText,
    )

    // save Old appellation
    setLogItemValue(
      logsData.bottle?.oldValues?.appellation,
      appellation,
      'appellation',
      setAppellationTextOld,
    )

    // save New appellation
    setLogItemValue(
      logsData.bottle?.newValues?.appellation,
      appellation,
      'appellation',
      setAppellationNewText,
    )

    // save Old wine_color_id
    setLogItemValue(
      logsData.bottle?.oldValues?.wine_color_id,
      colorList,
      'color_name',
      setColorTextOld,
    )

    // save new wine_color_id
    setLogItemValue(
      logsData.bottle?.newValues?.wine_color_id,
      colorList,
      'color_name',
      setColorTextNew,
    )
  }, [logsData])

  const ToggleClass = () => {
    setActive(!isActive)
  }

  useEffect(() => {
    getBottlesSpecification()
  }, [])

  useEffect(() => {
    // setting regions default value
    if (Object.keys(countryWithRegion).length) {
      getLogsDetails()

      for (let key in countryWithRegion) {
        if (key === bottleDetail?.country) {
          setRegionList(countryWithRegion[key])
        }
      }
      // set applilation as per region
      countryWithRegion[bottleDetail?.country]?.forEach((element) => {
        if (element.id === bottleDetail?.region_id) {
          setAppellationWithRegion(element.wine_appellation)
        }
      })

      //set size text

      sizeList.forEach((element) => {
        if (element.id === bottleDetail.size_id) {
          setSizeText(element.size)
        }
      })
      //set badge text

      badgeSelectList.forEach((element) => {
        if (element === bottleDetail.badge) {
          setBadgeText(element)
        }
      })
    }
  }, [bottleDetail])

  useEffect(() => {
    getAllWineryList()
  }, [])

  const getAllWineryList = () => {
    dispatch(getAllWineryListForDropDown()).then((res) => {
      setWineryList(res?.data?.data)
    })
  }

  const getLogsDetails = () => {
    let data = {
      userId: bottleDetail.user_id,
    }
    dispatch(getPublicDetailsLogs(data)).then((res) => {
      setLogsData(res?.data.data)
      // set if size changes
    })
  }

  const getBottlesSpecification = () => {
    dispatch(getBottleDropDownSpecifications()).then((res) => {
      const details = res.data
      const keys = Object.keys(details.regionList)
      setCountryWithRegion(details.regionList)
      setSizeList(details.SizeList)
      setColorList(details?.colorList?.reverse())
      setAppellation(details.appellationList)

      const still = details?.BottleSweetnessList?.filter(
        (b) => b?.wine_type === 'still' || b?.wine_type === null,
      )
      const sparkling = details?.BottleSweetnessList?.filter(
        (item) => item?.wine_type === 'sparkling' || item?.wine_type === null,
      )

      const sparklingSortOrder = [
        'Brut nature',
        'Extra-brut',
        'Brut',
        'Extra-dry',
        'Dry',
        'Semi-dry',
        'Sweet',
      ]

      sparkling.sort((a, b) => {
        const aIndex = sparklingSortOrder.indexOf(a.name)
        const bIndex = sparklingSortOrder.indexOf(b.name)
        return aIndex - bIndex
      })

      setStillList(still.length > 0 ? still : [])
      setSparklingList(sparkling.length > 0 ? sparkling : [])

      setSweetNessList(details.BottleSweetnessList)
      setAllCountry(keys)

      getBottleDetails()
    })
  }
  const getBottleDetails = () => {
    let bottleDetails = {
      id: bottleId,
    }
    dispatch(getBottleDetail(bottleDetails)).then((res) => {
      setBottleDetail(res.data.data)
    })
  }

  const bottleFormik = useFormik({
    enableReinitialize: true,
    validationSchema: editBottleSchema,
    initialValues: {
      winery_name: bottleDetail?.winery_name,
      brand_name: bottleDetail?.brand_name,
      bottle_image: bottleDetail?.bottle_image,
      wine_name: bottleDetail?.wine_name,
      country: bottleDetail?.country,
      region_id: bottleDetail?.region_id,
      appellation: bottleDetail?.appellation || '',
      wine_color_id: bottleDetail?.wine_color_id,
      size_id: bottleDetail?.size_id,
      badge: bottleDetail?.badge || '',
      description: bottleDetail?.description,
      wine_spectator_point: bottleDetail?.wine_spectator_point,
      wine_advocate_point: bottleDetail?.wine_advocate_point,
      wine_falstaff_point: bottleDetail?.wine_falstaff_point,
      wine_vinous_point: bottleDetail?.wine_vinous_point,
      wine_james_suckling_point: bottleDetail?.wine_james_suckling_point,
      wine_burgbound_point: bottleDetail?.wine_burgbound_point,
      sweetness_wine_id: bottleDetail?.wine_sweetness?.id,
      grape: bottleDetail?.grape || '',
      vineyard_name: bottleDetail?.vineyard_name,
      classification: bottleDetail?.classification,
      consist: `${getWineConsist(bottleDetail?.consist)}`,
      ageability: bottleDetail?.ageability || '',
      rarity: bottleDetail?.rarity || '',
      bottle_image_title: bottleDetail?.bottle_image_title || '',
      bottle_image_alt: bottleDetail?.bottle_image_alt || '',
      strength: bottleDetail?.strength || '',
      tannins: bottleDetail?.tannins || '',
      texture: bottleDetail?.texture || '',
      review: bottleDetail?.review || '',
      about_winery: bottleDetail?.about_winery || '',
      terroir_and_aging_method: bottleDetail?.terroir_and_aging_method || '',
      tasting_notes: bottleDetail?.tasting_notes || '',
      pairing: bottleDetail?.pairing || '',
    },
    onSubmit: (values) => {
      values['user_id'] = bottleDetail.user_id
      values['id'] = bottleId
      values['consist'] = getWineConsist(values?.consist)
      values['rarity'] = `${values.rarity}`
      values['strength'] = `${values.strength}`
      values['tannins'] = `${values.tannins}`
      values['texture'] = `${values.texture}`
      updateBottleDetails(values)
      if (!values['appellation']) {
        delete values['appellation']
      }
    },
  })

  const handlefilterOnWinerySelect = (targetValue) => {
    const selectedWinery = wineryList.find((el) => el.winery_name === targetValue)

    for (const key in countryWithRegion) {
      if (key === selectedWinery?.country) {
        setRegionList(countryWithRegion[key])
        break
      }
    }

    bottleFormik.setFieldValue('country', selectedWinery?.country)
    bottleFormik.setFieldValue('region_id', selectedWinery.region_obj?.id)
    bottleFormik.setFieldValue('appellation', selectedWinery.appellation_obj?.appellation?.id)

    countryWithRegion[selectedWinery?.country].forEach((element) => {
      if (element.id === selectedWinery.region_obj?.id) {
        setAppellationWithRegion(element.wine_appellation)
      }
    })
  }

  const updateBottleDetails = (data) => {
    dispatch(updateBottle(data)).then((res) => {
      toast.success(res.data.message)
      //save logs here
      saveBottleDataForLogs(data)
    })
  }
  const saveBottleDataForLogs = (newData) => {
    let pData = { ...bottleDetail }
    delete pData.id
    delete pData.user_id
    delete pData.bottle_image
    delete pData.vintage

    let details = {
      prevData: pData,
      newData: newData,
      formType: 'bottles',
      userId: bottleDetail.user_id,
    }

    dispatch(saveProfileOldData(details)).then((res) => {
      history.push('/panel/bottles')
    })
  }

  const handleCountryChange = (e, field) => {
    bottleFormik.setFieldValue(field, e.target.value)
    if (field === 'country') {
      bottleFormik.setFieldValue('region_id', '')
      bottleFormik.setFieldValue('appellation', '')
      setAppellationWithRegion([])
      for (const key in countryWithRegion) {
        if (key === e.target.value) {
          setRegionList(countryWithRegion[key])
        }
      }
    }
    if (field === 'region_id') {
      bottleFormik.setFieldValue('appellation', '')
      countryWithRegion[bottleFormik.values.country].forEach((element) => {
        if (element.id === e.target.value) {
          setAppellationWithRegion(element.wine_appellation)
        }
      })
    }
  }

  const handleSizeChange = (e, field) => {
    bottleFormik.setFieldValue(field, e.target.value)
    sizeList.forEach((element) => {
      if (element.id === e.target.value) {
        setSizeText(element.size)
      }
    })
    //set size text with id
  }

  const badgeSelectList = ['Icon', 'Rising Star', 'Hidden Gem']

  const handleBadgeChange = (e, field) => {
    bottleFormik.setFieldValue(field, e.target.value)
    // badgeSelectList.forEach((element) => {
    //   if (element.id === e.target.value) {
    //     setSizeText(element.size)
    //   }
    // })
  }

  const uploadFileToS3Image = async (input) => {
    input.persist()

    let file = input.target.files[0]
    let fname = file.name

    if (!isImage(fname)) {
      toast.error('File extension not supported!')
      input.target.value = null
    } else {
      uploadImageApiImage(file)
    }
  }

  const isImage = (icon) => {
    const ext = ['.jpg', '.jpeg', '.png', '.JPG', '.JPEG', '.PNG', '.SVG', '.svg']
    return ext.some((el) => icon.endsWith(el))
  }

  const uploadImageApiImage = (file) => {
    let formData = new FormData()
    formData.append('folderName', 'testing')
    formData.append('file', file)
    dispatch(uploadFileGc(formData)).then((res) => {
      bottleFormik.setFieldValue('bottle_image', res?.data.data[0]?.url)
    })
  }

  const deleteBottleImage = () => {
    bottleFormik.setFieldValue('bottle_image', '')
    if (inputRefBottle.current !== undefined) {
      inputRefBottle.current.value = ''
    }
  }

  const handleChange = (value) => {
    bottleFormik.setFieldValue('consist', value)
  }
  return (
    <MainLayout>
      <div className='winemakers-main create-public-page crtbtl edt'>
        <div
          className={cn('left', {
            brdr: isActive,
          })}
        >
          <div className='logs-controller'>
            <div className='top'>
              <h3 className='common-sub-heading'>Logs</h3>

              <button
                className={cn('logs-toggler', {
                  active: isActive,
                })}
                type='button'
                onClick={() => {
                  setShowBottom(!showBottom)
                  ToggleClass()
                }}
              ></button>
            </div>
            {showBottom && (
              <BottleLogs
                data={logsData}
                sizeText={sizeText}
                sizeTextOld={sizeTextOld}
                badgeText={badgeText}
                badgeTextOld={badgeTextOld}
                regionText={regionText}
                regionTextOld={regionTextOld}
                appellationOldText={appellationOldText}
                appellationNewText={appellationNewText}
                colorTextOld={colorTextOld}
                colorTextNew={colorTextNew}
              />
            )}
          </div>
        </div>

        <div className='right'>
          <Row className='rightrow'>
            <Col xs={24}>
              <Link to='/panel/bottles' className='back-btn'>
                <IconBack /> Back to bottles list
              </Link>
              <h1 className='common-heading mb-25'>Edit Wine Bottle</h1>
            </Col>
            <Col xl={14} className='crtbtl-left'>
              <Form onSubmit={bottleFormik.handleSubmit}>
                <div className='block' id='a'>
                  <WineBottleName bottleFormik={bottleFormik} />

                  <div className='genBottle_Id inputField__wrap '>
                    <Row gutter={20} className='w-100 bottle-files-handle'>
                      <Col md={12}>
                        <div className='inputField__label file-input__image'>
                          <div className='file-input__image-subheader'>
                            Image of the bottle<sup>*</sup>
                          </div>
                          <p>File types supported: JPG, PNG, GIF, SVG.</p>
                          <p>Max size: 5 MB , 1080x1920</p>
                        </div>
                        <div className='uploadField'>
                          <div className='profilePic'>
                            <div className='profilePic-inner'>
                              <input
                                type='file'
                                name='profile_image'
                                id=' profile_image'
                                className='input__input'
                                onChange={uploadFileToS3Image}
                                formEncType='multipart/form-data'
                                ref={inputRefBottle}
                                disabled={bottleFormik.values.bottle_image !== ''}
                              />
                              <label
                                className={cn('file-input__prof', {
                                  'editImage IconHidd': bottleFormik.values.bottle_image !== '',
                                })}
                                htmlFor='file-input'
                              >
                                <img
                                  style={{ width: 64, height: 64 }}
                                  src={bottleFormik.values.bottle_image}
                                  alt=''
                                />
                              </label>
                              {bottleFormik.touched.bottle_image && (
                                <p
                                  style={{
                                    color: 'red',
                                    fontSize: '13px',
                                    margin: 'auto',
                                    textAlign: 'center',
                                    position: 'absolute',
                                    marginTop: '5px',
                                    width: '100%',
                                  }}
                                >
                                  {bottleFormik.errors.bottle_image}
                                </p>
                              )}
                            </div>
                          </div>

                          {bottleFormik.values.bottle_image && (
                            <div className='editand_DltBtn'>
                              <button
                                type='button'
                                onClick={() => deleteBottleImage()}
                                className='DeltBtnsIcon'
                              >
                                <IconDelete />
                              </button>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <InputField
                      name='bottle_image_title'
                      label='Image Title (attribute title)'
                      placeholder='Wine name...'
                      id='bottle_image_title'
                      onChange={(event) => {
                        bottleFormik.setFieldValue('bottle_image_title', event.target.value)
                      }}
                      value={bottleFormik.values.bottle_image_title}
                      formik={bottleFormik}
                    />
                  </div>
                  <div>
                    <InputField
                      name='bottle_image_alt'
                      label='Image Alt (attribute alt)'
                      placeholder='Wine name...'
                      id='bottle_image_alt'
                      onChange={(event) => {
                        bottleFormik.setFieldValue('bottle_image_alt', event.target.value)
                      }}
                      value={bottleFormik.values.bottle_image_alt}
                      formik={bottleFormik}
                    />
                  </div>
                </div>
                <div className='block' id='b'>
                  <div className='Header'>
                    <h3 className='common-sub-heading'>2: Details</h3>
                    <p>
                      The information you provide below are key features that will be displayed on
                      our public web page highlighting the features of the labels
                    </p>
                  </div>

                  <div className='cus_drop inputField__wrap'>
                    <label className='drop_label'>
                      Wine Producer<sup>*</sup>
                    </label>
                    <select
                      id='winery_name'
                      value={bottleFormik.values.winery_name}
                      onChange={(e) => {
                        bottleFormik.setFieldValue('winery_name', e.target.value)
                        handlefilterOnWinerySelect(e.target.value)
                      }}
                    >
                      <option value='' disabled>
                        Select producer of the wine
                      </option>
                      {wineryList.map((option) => (
                        <option
                          key={option.userId}
                          value={option.winery_name}
                          name={option.winery_name}
                        >
                          {option.winery_name}
                        </option>
                      ))}
                    </select>
                    {bottleFormik.touched.winery_name && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          marginRight: 'auto',
                          marginLeft: '0',
                        }}
                      >
                        {bottleFormik.errors.winery_name}
                      </p>
                    )}
                  </div>

                  <div className='cus_drop inputField__wrap'>
                    <label className='drop_label'>
                      Country<sup>*</sup>
                    </label>
                    <select
                      id='country'
                      value={bottleFormik.values.country}
                      onChange={(e) => handleCountryChange(e, 'country')}
                    >
                      <option value=''>Select country</option>
                      {allCountry.map((option) => (
                        <option key={`country-${option}`} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    {bottleFormik.touched.country && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          marginRight: 'auto',
                          marginLeft: '0',
                        }}
                      >
                        {bottleFormik.errors.country}
                      </p>
                    )}
                  </div>
                  <div className='cus_drop inputField__wrap'>
                    <label className='drop_label'>
                      Region<sup>*</sup>
                    </label>
                    <select
                      id='region_id'
                      value={bottleFormik.values.region_id}
                      onChange={(e) => handleCountryChange(e, 'region_id')}
                    >
                      <option value='' disabled>
                        Select region
                      </option>
                      {regionList.map((option) => (
                        <option key={`r-${option?.id}`} value={option?.id}>
                          {option.region_name}
                        </option>
                      ))}
                    </select>
                    {bottleFormik.touched.region_id && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          marginRight: 'auto',
                          marginLeft: '0',
                        }}
                      >
                        {bottleFormik.errors.region_id}
                      </p>
                    )}
                  </div>
                  <div className='cus_drop inputField__wrap'>
                    <label className='drop_label'>Appellation</label>
                    <select
                      id='appellation'
                      value={bottleFormik.values.appellation}
                      onChange={(e) => handleCountryChange(e, 'appellation')}
                    >
                      <option value='' disabled>
                        Select appellation
                      </option>
                      {appellationWithRegion.map((option, index) => (
                        <option key={`apr-${option.id}`} value={option.id}>
                          {option.appellation}
                        </option>
                      ))}
                    </select>
                    {bottleFormik.touched.appellation && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          marginRight: 'auto',
                          marginLeft: '0',
                        }}
                      >
                        {bottleFormik.errors.appellation}
                      </p>
                    )}
                  </div>

                  <InputField
                    name='vineyard_name'
                    label='Vineyard name or Cru'
                    placeholder='Ex. Jacuzzi Family Vineyards, Chateau Latour'
                    id='vineyard_name'
                    onChange={(event) => {
                      bottleFormik.setFieldValue('vineyard_name', event.target.value)
                    }}
                    value={bottleFormik.values.vineyard_name}
                    formik={bottleFormik}
                  />
                  <InputField
                    name='wine_name'
                    label='Cuvée'
                    placeholder='Les combettles'
                    id='wine_name'
                    onChange={(event) => {
                      bottleFormik.setFieldValue('wine_name', event.target.value)
                    }}
                    value={bottleFormik.values.wine_name}
                    formik={bottleFormik}
                  />
                  <InputField
                    name='grape'
                    label='Grape'
                    placeholder='Ex. Grape'
                    id='grape'
                    onChange={(event) => {
                      bottleFormik.setFieldValue('grape', event.target.value)
                    }}
                    value={bottleFormik.values.grape}
                    formik={bottleFormik}
                  />
                  <div className='cus_drop inputField__wrap'>
                    <label className='drop_label'>
                      Color of the wine<sup>*</sup>
                    </label>
                    <select
                      id='wine_color_id'
                      value={bottleFormik.values.wine_color_id}
                      onChange={(e) => handleCountryChange(e, 'wine_color_id')}
                    >
                      <option value='' disabled>
                        Select wine color
                      </option>
                      {colorList.map((option) => (
                        <option key={`cl-${option.id}`} value={option.id}>
                          {option.color_name}
                        </option>
                      ))}
                    </select>
                    {bottleFormik.touched.wine_color_id && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          marginRight: 'auto',
                          marginLeft: '0',
                        }}
                      >
                        {bottleFormik.errors.wine_color_id}
                      </p>
                    )}
                  </div>

                  <div className='BtnRadio'>
                    <label className='drop_label mb-25'>Consistency: </label>

                    <RadioButton
                      label='Still'
                      className='btnSetRadio'
                      id='consist1'
                      name='consist'
                      value='still'
                      checked={bottleFormik.values.consist === 'still'}
                      onChange={handleChange}
                    />
                    <RadioButton
                      label='Sparkling'
                      className='btnSetRadio'
                      id='consist2'
                      name='consist'
                      value='sparking'
                      checked={bottleFormik.values.consist === 'sparking'}
                      onChange={handleChange}
                    />
                    {bottleFormik.touched.consist && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          marginRight: 'auto',
                          marginLeft: '0',
                        }}
                      >
                        {bottleFormik.errors.consist}
                      </p>
                    )}
                  </div>

                  <div className='cus_drop inputField__wrap'>
                    <label className='drop_label'>
                      Sweetness of wine<sup>*</sup>
                    </label>
                    <select
                      id='sweetness_wine_id'
                      value={bottleFormik.values.sweetness_wine_id}
                      onChange={(e) =>
                        bottleFormik.setFieldValue('sweetness_wine_id', e.target.value)
                      }
                    >
                      <option value='' disabled>
                        Select sweetness
                      </option>
                      {bottleFormik.values.consist !== 'still' &&
                        bottleFormik.values.consist !== 'sparking' &&
                        sweetNessList.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      {bottleFormik.values.consist === 'still' &&
                        stillList.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      {bottleFormik.values.consist === 'sparking' &&
                        sparklingList.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                    </select>
                    {bottleFormik.touched.sweetness_wine_id && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          marginRight: 'auto',
                          marginLeft: '0',
                        }}
                      >
                        {bottleFormik.errors.sweetness_wine_id}
                      </p>
                    )}
                  </div>

                  <InputField
                    name='classification'
                    label='Classification'
                    placeholder='(Ex. Grand Cru, Premier Cru, DOCG)'
                    id='classification'
                    onChange={(event) => {
                      bottleFormik.setFieldValue('classification', event.target.value)
                    }}
                    value={bottleFormik.values.classification}
                    formik={bottleFormik}
                  />
                  <div className='cus_drop inputField__wrap'>
                    <label className='drop_label'>
                      Size<sup>*</sup>
                    </label>
                    <select
                      id='size_id'
                      value={bottleFormik.values.size_id}
                      onChange={(e) => handleSizeChange(e, 'size_id')}
                    >
                      <option value='' disabled>
                        Select size
                      </option>
                      {sizeList.map((option) => (
                        <option key={`sz-${option.id}`} value={option.id}>
                          {option.size}
                        </option>
                      ))}
                    </select>
                    {bottleFormik.touched.size_id && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          marginRight: 'auto',
                          marginLeft: '0',
                        }}
                      >
                        {bottleFormik.errors.size_id}
                      </p>
                    )}
                  </div>
                  <div className='cus_drop inputField__wrap'>
                    <label className='drop_label'>Badge</label>
                    <select
                      id='badge'
                      value={bottleFormik.values.badge}
                      onChange={(e) => handleBadgeChange(e, 'badge')}
                    >
                      <option value=''></option>
                      {badgeSelectList.map((option) => (
                        <option key={`bgs-${option}`} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    {bottleFormik.touched.badge && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          marginRight: 'auto',
                          marginLeft: '0',
                        }}
                      >
                        {bottleFormik.errors.badge}
                      </p>
                    )}
                  </div>
                </div>
                <Notes bottleFormik={bottleFormik} action='edit' />
              </Form>
            </Col>
            <Col xl={10} className='crtbtl-right'>
              <div className='inner'>
                <div className='Header'>
                  <h3 className='common-sub-heading'>Bottle Preview</h3>
                </div>
                <CardIframe
                  data={{
                    bottle_data: { ...bottleDetail },
                    users: { creator_meta: { country_code: bottleDetail.countrycode } },
                  }}
                />
                {/* <CardGrid
                  bgColor="#e0ddff"
                  bgImage={redWineBackgroundH}
                  wineBottleImage={bottleFormik.values.bottle_image}
                  // winerLableImage={bottleFormik.values.label_image}
                  wineTitle={bottleFormik.values.brand_name}
                  winerTitle={bottleFormik.values.winery_name}
                  wineMakerImage={dummyImg}
                  wineMaker={bottleDetail?.email}
                  size={sizeText}
                  grape={bottleDetail?.grape}
                  appellation={appellationText ? appellationText : bottleDetail?.appellation_obj?.appellation}
                  region_name={bottleDetail?.region_obj?.region_name}

                  wine_name={bottleDetail?.wine_name}
                /> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </MainLayout>
  )
}

EditBottle.propTypes = {}

export default EditBottle
