import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { object as yupObject, string as yupString, number as yupNumber } from 'yup'
import { Formik, Form } from 'formik'
import { useDispatch } from 'react-redux'
import { Checkbox, Col, Row } from 'antd'
import moment from 'moment'

import InputField from 'components/common/InputField/InputField'
import DropdownField from 'components/common/DropdownField/DropdownField'
import {
  CODE_ISSUER,
  CODE_ISSUER_OPTIONS,
  CONDITION_TYPES,
  CONDITION_TYPES_OPTIONS,
  DISCOUNT_TYPES,
  DISCOUNT_TYPES_OPTIONS,
  SALES_TYPES,
  SALES_TYPES_OPTIONS,
} from '../../const'
import { ButtonCustom } from 'components/button/ButtonCustom'
import { checkIfDiscountCodeExists } from 'redux/_actions/discountCodes.action'
import { toast } from '../../../../../components/Toast/Toast'
import { FormField } from 'components/FormField'

const DiscountCodeForm = ({ data, onSubmit, refTags }) => {
  const dispatch = useDispatch()
  const [isDuplicate, setIsDuplicate] = useState('')
  const [useConditions, setUseConditions] = useState(
    data?.additional_conditions?.use_conditions || false,
  )

  const initialValues = {
    discount_code: data?.discount_code || '',
    start_date: data?.start_date || '',
    end_date: data?.end_date || '',
    discount_type: data?.discount_type || DISCOUNT_TYPES.NUMBER,
    discount_value: data?.discount_value || '',
    sale_type: data?.sale_type || SALES_TYPES.PRIMARY,
    code_issuer: data?.code_issuer || CODE_ISSUER.WINECHAIN,
    is_valid: !!data?.is_valid || true,
    use_conditions: data?.additional_conditions?.use_conditions || false,
    number_used: data?.additional_conditions?.number_used || '',
    applied_drops_only: data?.additional_conditions?.applied_drops_only || '',
    customer_conditions_emails: data?.additional_conditions?.customer_conditions_emails || '',
    first_purchase: data?.additional_conditions?.first_purchase || 0,
    is_referral: data?.additional_conditions?.is_referral || 0,
    ref_tag: data?.additional_conditions?.ref_tag || '',
    no_nft_bought: data?.additional_conditions?.no_nft_bought || '',
    is_discounted_drop: data?.additional_conditions?.is_discounted_drop || 0,
    condition_type: data?.additional_conditions?.condition_type || CONDITION_TYPES.AND,
  }

  console.log('initialValues:', initialValues)

  const validationSchema = {
    discount_code: yupString().max(100, 'Must less then 100 digits'),
    start_date: yupString().required('is required'),
    end_date: yupString().required('is required'),
    discount_type: yupString().required('Required'),
    discount_value: yupNumber().min(1, '1 is minimum').required('Required'),
    sale_type: yupString().required('Required'),
    code_issuer: yupString().required('Required'),
    number_used: yupNumber().min(1, '1 is minimum'),
    no_nft_bought: yupNumber().min(1, '1 is minimum'),
  }
  if (data) {
    validationSchema.discount_code = yupString()
      .max(100, 'Must less then 100 digits')
      .required('Required')
  }

  const onFormSubmit = (values) => {
    const currentTime = moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
    const startTime = moment(values.start_date).format('YYYY-MM-DD 00:00:00')
    const endTime = moment(values.end_date).format('YYYY-MM-DD 23:59:59')
    const is_valid = currentTime >= startTime && currentTime <= endTime

    // check if the discount code exists for
    //  - changed code
    //  - new code
    const isChanged = data?.discount_code && values.discount_code !== data?.discount_code
    const isNew = !data
    if (values.discount_code && (isChanged || isNew)) {
      const successFn = (res) => {
        if (res.data) {
          toast.error(`${values.discount_code} already exists`)
          setIsDuplicate(values.discount_code)
        } else {
          onSubmit({
            ...values,
            start_date: values.start_date.split('.')[0],
            end_date: values.end_date.split('.')[0],
            is_valid,
          })
        }
      }

      const errorFn = (res) => {
        toast.error(res?.data?.message || 'Failed')
      }
      dispatch(
        checkIfDiscountCodeExists({ discountCodeValue: values.discount_code }, successFn, errorFn),
      )
      return
    }
    onSubmit({
      ...values,
      start_date: values.start_date.split('.')[0],
      end_date: values.end_date.split('.')[0],
      is_valid,
    })
  }

  const getDiscountCodeErrors = (values, errors) =>
    isDuplicate && isDuplicate === values.discount_code
      ? { discount_code: `${values.discount_code} already exists` }
      : errors

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yupObject(validationSchema)}
      onSubmit={onFormSubmit}
      enableReinitialize
    >
      {({ errors, touched, values, isValid, dirty, handleChange, setFieldValue, setTouched }) => (
        <Form>
          <InputField
            name='discount_code'
            label='Discount Code'
            placeholder='E.g. Christmas-Wine'
            id='discount_code'
            onChange={handleChange}
            value={values.discount_code}
            formik={{
              errors: getDiscountCodeErrors(values, errors),
            }}
            maxLength={255}
          />

          <div className='perameter_Inputs'>
            <FormField
              label='Start Date'
              isRequired
              type='datetime'
              onFocus={() => setTouched({ start_date: true })}
              input={{
                value: values.start_date ? new Date(values.start_date) / 1000 : '',
                onChange: (e) =>
                  setFieldValue('start_date', e ? new Date(e * 1000).toISOString() : ''),
              }}
              placeholder=''
              min={0.1}
              meta={{ touched: touched.start_date, error: errors.start_date }}
              showTimeInput
              dateFormat='MM/dd/yyyy h:mm aa'
            />
          </div>

          <div className='perameter_Inputs'>
            <FormField
              label='End Date'
              isRequired
              type='datetime'
              onFocus={() => setTouched({ end_date: true })}
              input={{
                value: values.end_date ? new Date(values.end_date) / 1000 : '',
                onChange: (e) =>
                  setFieldValue('end_date', e ? new Date(e * 1000).toISOString() : ''),
              }}
              placeholder=''
              showTimeInput
              min={0.1}
              meta={{ touched: touched.end_date, error: errors.end_date }}
              dateFormat='MM/dd/yyyy h:mm aa'
            />
          </div>

          <DropdownField
            label='Discount Type'
            placeholder=''
            name='discount_type'
            value={values.discount_type}
            onChange={handleChange}
            options={DISCOUNT_TYPES_OPTIONS}
            isRequired
            error={errors?.discount_type}
          />

          <InputField
            name='discount_value'
            label='Discount Value'
            placeholder='E.g. 10'
            id='discount_value'
            onChange={handleChange}
            value={values.discount_value}
            formik={{ errors }}
            type='number'
            isRequired
          />

          <Row align='middle' className='perameter_Inputs'>
            <Checkbox
              name='is_discounted_drop'
              label='Is discounted drop'
              checked={values.is_discounted_drop}
              onChange={(e) => {
                setFieldValue('is_discounted_drop', +e.target.checked || 0)
                if (e.target.checked) {
                  setFieldValue('sale_type', SALES_TYPES.PRIMARY)
                  setFieldValue('use_conditions', 1)
                  setUseConditions(true)
                }
              }}
            />
            <label className='inputField__label' style={{ marginLeft: '10px' }}>
              Is Discounted Drop
            </label>
          </Row>

          <DropdownField
            label='Sale Type'
            placeholder=''
            name='sale_type'
            value={values.sale_type}
            onChange={handleChange}
            options={SALES_TYPES_OPTIONS}
            isRequired
            error={errors?.sale_type}
            disabled={!!values.is_discounted_drop}
          />

          <DropdownField
            label='Code Issuer'
            placeholder=''
            name='code_issuer'
            value={values.code_issuer}
            onChange={handleChange}
            options={CODE_ISSUER_OPTIONS}
            isRequired
            error={errors?.code_issuer}
          />
          <Row align='middle' className='perameter_Inputs'>
            <Checkbox
              name='use_conditions'
              label='Use additional conditions'
              checked={values.use_conditions}
              onChange={(e) => {
                setFieldValue('use_conditions', +e.target.checked || 0)
                setUseConditions(!!e.target.checked)
              }}
            />
            <label className='inputField__label' style={{ marginLeft: '10px' }}>
              Use additional conditions
            </label>
          </Row>
          {useConditions && (
            <>
              <DropdownField
                label={
                  <>
                    <span>Condition Type (OR/AND):</span>
                    <br />
                    <strong>OR</strong> <i>- one of the conditions must be met,</i>{' '}
                    <strong>AND</strong> <i>- all chosen conditions must be met</i>
                  </>
                }
                placeholder=''
                name='condition_type'
                value={values.condition_type}
                onChange={handleChange}
                options={CONDITION_TYPES_OPTIONS}
                error={errors?.condition_type}
                disabled={!!values.is_discounted_drop}
              />

              <InputField
                name='number_used'
                label='Number of times the code can be used'
                placeholder='min 1, no limit on max'
                id='number_used'
                onChange={handleChange}
                value={values.number_used}
                formik={{ errors }}
                type='number'
                disabled={!!values.is_discounted_drop}
              />

              <InputField
                name='applied_drops_only'
                label='Applied on drops only'
                placeholder='E.g. 3145405, 3145406, 3145407'
                id='applied_drops_only'
                onChange={handleChange}
                value={values.applied_drops_only}
                error={errors?.applied_drops_only}
                type='textArea'
              />

              <h3>customer conditions</h3>
              <InputField
                name='customer_conditions_emails'
                label='List Emails'
                placeholder='E.g. bob@gmail.com, winechain@gmail.com'
                id='customer_conditions_emails'
                onChange={handleChange}
                value={values.customer_conditions_emails}
                error={errors?.customer_conditions_emails}
                type='textArea'
                disabled={!!values.is_discounted_drop}
              />
              <Row align='middle' className='perameter_Inputs'>
                <Checkbox
                  name='first_purchase'
                  label='First Purchase'
                  checked={values.first_purchase}
                  onChange={(e) => {
                    setFieldValue('first_purchase', +e.target.checked || 0)
                  }}
                  disabled={!!values.is_discounted_drop}
                />
                <label className='inputField__label' style={{ marginLeft: '10px' }}>
                  First Purchase
                </label>
              </Row>
              <Row align='middle' className='perameter_Inputs'>
                <Checkbox
                  name='is_referral'
                  label='User Is Referral'
                  checked={values.is_referral}
                  onChange={(e) => {
                    setFieldValue('is_referral', +e.target.checked || 0)
                  }}
                  disabled={!!values.is_discounted_drop}
                />
                <label className='inputField__label' style={{ marginLeft: '10px' }}>
                  User Is Referral
                </label>
              </Row>
              <InputField
                name='ref_tag'
                label='Ref tag'
                placeholder='E.g. RFa9d83c7c-34c4-4016-8f02-c3401b9a137f, GFa9d83c7c-34c4-4016-8f02-c3401b9a137f'
                id='ref_tag'
                onChange={handleChange}
                value={values.ref_tag}
                error={errors?.ref_tag}
                type='textArea'
                disabled={!!values.is_discounted_drop}
              />
              <InputField
                name='no_nft_bought'
                label='# of NFTs bought by the customer'
                placeholder='min 0, max no limit'
                id='no_nft_bought'
                onChange={handleChange}
                value={values.no_nft_bought}
                formik={{ errors }}
                type='number'
                disabled={!!values.is_discounted_drop}
              />
            </>
          )}

          <Row justify='end'>
            <Col>
              <ButtonCustom
                style={{ margin: 0 }}
                type='submit'
                title='Save'
                customClass='saveBtn'
                disabled={
                  !(dirty && isValid) || !!(isDuplicate && isDuplicate === values.discount_code)
                }
              />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

DiscountCodeForm.defaultProps = {
  data: null,
  errors: null,
}
DiscountCodeForm.propTypes = {
  data: PropTypes.shape({}),
  errors: PropTypes.shape({}),
}

export default DiscountCodeForm
