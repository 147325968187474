import React, { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { Form, Grid, Popup } from 'semantic-ui-react'
import { useFormik } from 'formik'
import InputField from '../../../../components/common/InputField/InputField'
import { ButtonCustom } from '../../../../components/button/ButtonCustom'
import BorderButton from '../../../../components/button/BorderButton'
import { toast } from '../../../../components/Toast/Toast'
import { WinerMakerAction } from '../../../../redux/_actions'
import { useDispatch } from 'react-redux'
import Info_Icon from '../../../../Assets/image/Info_Icon.svg'
import { IconCrop, AddIcon, IconDelete } from '../../../../Assets/image/svgimages/sVGImg'
import './AddWinemaker.scss'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import useDebounce from '../../../../../src/hooks/useDebounce'
import { canvasPreview } from '../../../../components/canvasPreview'

const winerSchema = Yup.object().shape({
  header: Yup.string().required('This field is required').max(150, 'Maximum 150 character allowed'),
  background_img: Yup.string().required('This field is required'),
  body_text: Yup.string().required('This field is required'),
})

const OurStoryStepSec = (props) => {
  let { setStep, userId, winerDetails, getWinerAllDetails, setIsLoader, status, setError, error } =
    props
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const { updatStoryWinerDetail, saveProfileOldData, uploadFileGc } = WinerMakerAction
  const [editorState, setEditorState] = useState()
  const [storyData, setStoryData] = useState({})
  const [editorJson, setEditorJson] = useState({})

  const [oldDataStep2, setOldDataStep2] = useState({})
  const dispatch = useDispatch()
  const inputRefContentImage = React.useRef()

  useEffect(() => {
    if (winerDetails.length > 0 && status !== 0) {
      let stepData = {
        // background_img: winerDetails[0]?.creator_ourstory_meta?.imageUrl,
        body_text: winerDetails[0]?.creator_ourstory_meta?.text,
        header: winerDetails[0]?.creator_ourstory_meta?.header,
      }
      setOldDataStep2(stepData)
    }

    if (
      winerDetails[0]?.creator_ourstory_meta?.text_json &&
      Object.keys(winerDetails[0]?.creator_ourstory_meta?.text_json).length > 0
    ) {
      setEditorJson(winerDetails[0]?.creator_ourstory_meta?.text_json)
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(winerDetails[0]?.creator_ourstory_meta?.text_json),
        ),
      )
    }
  }, [winerDetails, status])

  const wineStoryFormik = useFormik({
    enableReinitialize: true,
    validationSchema: winerSchema,
    initialValues: {
      body_text_json: null,
      body_text:
        winerDetails.length > 0 && winerDetails[0]?.creator_ourstory_meta !== null
          ? winerDetails[0]?.creator_ourstory_meta?.text
          : '',
      header:
        winerDetails.length > 0 && winerDetails[0]?.creator_ourstory_meta !== null
          ? winerDetails[0]?.creator_ourstory_meta?.header
          : '',
      background_img:
        winerDetails.length > 0 && winerDetails[0]?.creator_ourstory_meta !== null
          ? winerDetails[0]?.creator_ourstory_meta?.imageUrl
          : '',
    },

    onSubmit: (values) => {
      setError(false)
      values['userId'] = userId
      values['body_text_json'] = editorJson
      if (editorJson && wineStoryFormik?.values?.body_text) {
        addOurStoryApiCall(values)
      }
    },
  })

  const addOurStoryApiCall = (values) => {
    setIsLoader(true)
    dispatch(updatStoryWinerDetail(values)).then((res) => {
      getWinerAllDetails()
      if (status !== 0) {
        saveStepSecData(values)
      }
      setStep(3)
      setIsLoader(false)
    })
  }

  const saveStepSecData = (wineMakerNew) => {
    let newData = { ...wineMakerNew }
    delete newData.userId
    let details = {
      prevData: oldDataStep2,
      newData: newData,
      formType: 'form_2',
      userId: userId,
    }

    dispatch(saveProfileOldData(details)).then((res) => {
      setStep(3)
      setIsLoader(false)
    })
  }

  const isImage = (icon) => {
    const ext = ['.jpg', '.jpeg', '.png', '.JPG', '.JPEG', '.PNG']
    return ext.some((el) => icon.endsWith(el))
  }

  const uploadImageApi = (file) => {
    let formData = new FormData()
    formData.append('folderName', 'testing')
    formData.append('file', file)

    dispatch(uploadFileGc(formData)).then((res) => {
      wineStoryFormik.setFieldValue('background_img', res?.data.data[0]?.url)
    })
  }

  const uploadFileToS3 = async (input) => {
    input.persist()
    let file = input.target.files[0]
    let fname = file.name

    if (!isImage(fname)) {
      input.target.value = null
      toast.error('File extension not supported!')
      setIsLoader(false)
    } else {
      uploadImageApi(file)
    }
  }

  useDebounce(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
      }
    },
    100,
    [completedCrop],
  )

  const uploadCropped = () => {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }
    previewCanvasRef.current.toBlob(
      (blob) => {
        if (!blob) {
          throw new Error('Failed to create blob')
        }
        if (blobUrlRef.current) {
          URL.revokeObjectURL(blobUrlRef.current)
        }
        blobUrlRef.current = URL.createObjectURL(blob)
        uploadImageApi(blob)
      },
      { useCORS: true },
      'image/jpeg',
    )
  }

  const gotToNextPage = () => {
    if (
      winerDetails.length > 0 &&
      wineStoryFormik?.initialValues?.body_text &&
      wineStoryFormik?.initialValues?.header
    ) {
      setStep(3)
    } else {
      toast.error('Please add required information')
    }
  }

  const deleteContentImage = () => {
    setError(true)
    inputRefContentImage.current.value = ''
    wineStoryFormik.setFieldValue('background_img', '')
  }

  const editorValueHandler = async (e, keyValue) => {
    const data = {}
    if (keyValue !== 'text_json') {
      data[keyValue] = e
      wineStoryFormik.setFieldValue('body_text', e)
      setStoryData({ ...storyData, ...data })
    }
    if (keyValue === 'text_json') {
      setEditorJson(e)
    }
  }

  return (
    <div className='block' id='s2'>
      <div className='Header sectionInfo'>
        <h3 className='heading'>Key content details</h3>
        <Popup
          className='trigerEdit'
          trigger={
            <div className='InfoToggle'>
              <img src={Info_Icon} alt='Info_Icon' />
            </div>
          }
          // header='Movie Search'
          content='This section covers details of the winery such as history of the winery, their philosophy, their specialities, etc. Please work with wineries to incorporate relevant and critical winery details'
          on={['hover', 'click']}
          position='bottom right'
        />
      </div>
      <Form onSubmit={wineStoryFormik.handleSubmit}>
        <div className='flex d-column'>
          <InputField
            id='header'
            label='Title'
            isRequired
            placeholder='Background story'
            type='text'
            showLimit={`${wineStoryFormik.values.header.length} / 150`}
            onChange={(event) => {
              if (event.target.value === '' || event.target.value === null) {
                setError(true)
              }
              wineStoryFormik.setFieldValue('header', event.target.value)
            }}
            value={wineStoryFormik.values.header}
            maxLength={150}
            formik={wineStoryFormik}
          />
        </div>

        <div></div>
        <div>
          <label className='inputField__label'>Content image*</label>
          <p className='sectionInfo sizeImg mt-24'>
            Image: Max resolution 1000 x 1000 (crop ratio: 46 / 19), max size 10mb
          </p>
        </div>

        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={8} className='padd-right'>
            <div className='uploadField'>
              <div className='profilePic'>
                <div className='profilePic-inner big'>
                  <input
                    id='background_img'
                    type='file'
                    name='label'
                    className='input__input'
                    onChange={uploadFileToS3}
                    formEncType='multipart/form-data'
                    ref={inputRefContentImage}
                    title={wineStoryFormik.values?.background_img === '' ? 'No file choosen' : ''}
                  />

                  <label
                    className={
                      wineStoryFormik.values?.background_img !== ''
                        ? 'file-input__prof hidden BAckImageSet'
                        : 'file-input__prof'
                    }
                    htmlFor='file-input'
                  />
                  <div className='down-button' />

                  <ReactCrop
                    crop={crop}
                    onComplete={(c) => setCompletedCrop(c)}
                    onChange={(c) => setCrop(c)}
                    aspect={46 / 19}
                    style={{ zIndex: 9 }}
                    minWidth={50}
                    minHeight={50}
                  >
                    <img
                      ref={imgRef}
                      src={`${wineStoryFormik.values?.background_img}`}
                      alt=''
                      crossOrigin='anonymous'
                      width='100%'
                      height='auto'
                    />
                  </ReactCrop>

                  {!!completedCrop && (
                    <>
                      <div>
                        <canvas
                          ref={previewCanvasRef}
                          style={{
                            border: '1px solid black',
                            objectFit: 'contain',
                            width: completedCrop.width,
                            height: completedCrop.height,
                          }}
                        />
                      </div>
                    </>
                  )}

                  {wineStoryFormik.errors.background_img && (
                    <p
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        margin: 'auto',
                        position: 'absolute',
                        textAlign: 'center',
                        marginTop: '5px',
                        width: '100%',
                      }}
                    >
                      {wineStoryFormik.errors.background_img}
                    </p>
                  )}
                  <div className='imageActions'>
                    {!!completedCrop && (
                      <button
                        className='imageActions__button'
                        type='button'
                        onClick={() => uploadCropped()}
                      >
                        <IconCrop />
                      </button>
                    )}
                    {wineStoryFormik.values?.background_img !== '' && (
                      <button
                        className='imageActions__button'
                        type='button'
                        onClick={() => deleteContentImage()}
                      >
                        <IconDelete />
                      </button>
                    )}

                    {wineStoryFormik.values?.background_img !== '' && (
                      <button
                        className='imageActions__button'
                        type='button'
                        onClick={() => inputRefContentImage.current.click()}
                      >
                        <AddIcon />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid>

        <div className='editRichText'>
          <div className='sectionInfo'>
            <label className='inputField__label'>
              Body Text <sup className='starIsRequired'>*</sup>
            </label>
          </div>

          <div className='sectionInfo mt-1'>
            {}
            <Editor
              placeholder='Type your text here'
              onEditorStateChange={(e) => {
                setEditorState(e)
                wineStoryFormik.setFieldValue('body_text', e.getCurrentContent().getPlainText())
                editorValueHandler(convertToRaw(e.getCurrentContent()), 'text_json')
                editorValueHandler(e.getCurrentContent().getPlainText(), 'text')
                if (
                  e.getCurrentContent().getPlainText() === '' ||
                  e.getCurrentContent().getPlainText() === null
                ) {
                  setError(true)
                }
              }}
              stripPastedStyles={true} // Paste without source styling
              editorStyle={{ height: '200px' }}
              editorState={editorState}
              toolbar={{
                fontFamily: {
                  options: [
                    'Lora',
                    'Cinzel',
                    'Arial',
                    'Georgia',
                    'Impact',
                    'Tahoma',
                    'Times New Roman',
                    'Verdana',
                  ],
                },
              }}
            />
          </div>
          {wineStoryFormik.errors.body_text && (
            <p style={{ color: 'red' }}>{wineStoryFormik.errors.body_text}</p>
          )}
        </div>

        <div className='btns-spacing'>
          <BorderButton
            text='Previous step'
            className='addNew_Btn'
            type='button'
            onClick={() => setStep(1)}
          />
          <ButtonCustom
            disabled={!(wineStoryFormik.isValid && wineStoryFormik.dirty)}
            type='submit'
            title='Save'
            customClass='saveBtn'
          />
          {winerDetails[0]?.creator_ourstory_meta !== null &&
            Object.keys(winerDetails[0]?.creator_ourstory_meta).length > 0 && (
              <ButtonCustom
                type='button'
                title='Skip'
                customClass='saveBtn'
                onClick={() => gotToNextPage()}
                disabled={error}
              />
            )}
        </div>
      </Form>
    </div>
  )
}
export default OurStoryStepSec
