import React, { useMemo, useState, useEffect } from 'react'
import { Popup, Form } from 'semantic-ui-react'
import { Col, Row } from 'antd'
import countryList from 'react-select-country-list'
import MainLayout from '../../../../components/HOC/MainLayout/MainLayout'
import InputField from '../../../../components/common/InputField/InputField'
import { ButtonCustom } from '../../../../components/button/ButtonCustom'
import RadioButton from '../../../../components/common/RadioButton/RadioButton'
import Info_Icon from '../../../../Assets/image/Info_Icon.svg'
import WineftLogs from './WineftLogs'
import { useFormik } from 'formik'
import CalendarDateTime from '../../../../components/common/DateTime/CalendarDateTime.jsx'
import CardIframe from '../../../../components/common/CardIframe'

import { Bottles } from '../../../../redux/_actions'
import { useDispatch } from 'react-redux'
import './EditWineft.scss'
import cloneDeep from 'lodash/cloneDeep'
import { TIME_ZONES } from '../../../../utils/time'

import {
  getNftDetails,
  updateNftDetails,
  sendToApproveNft,
  saveDropLogs,
  getDropLogs,
} from '../../../../redux/_actions/nft.action'
import CustomDropdown from '../../../../components/common/CustomDropdown/CustomDropdown'
import moment from 'moment'
import { useHistory } from 'react-router'
import { toast } from '../../../../components/Toast/Toast'
import { wineNftSchema, emptyWineNftSchema } from '../data/schema'
import { CREATE_NFT_STATUSES } from '../data/consts'
import WineName from './WineName/WineName'
import WineDescription from './WineDescription/WineDescription'
import getWineNameFromBottleDetails from '../helpers/helpers'
import { tagCustomEventSendPulse } from '../../../../utils/sendPulseApi/sendPulseApi'
import { SENDPULSE_EVENTS } from '../../../../utils/sendPulseApi/constants/constants'
import { vatModes } from '../data/dropMode'

const { getBottleDetail } = Bottles

const EditWineft = (props) => {
  let dropDetails = props.location.state
  const history = useHistory()

  const [start_drop_event_date, setstart_drop_event] = useState()
  const [finish_drop_event_date, setFinish_drop_event_date] = useState()
  const [bottle_release_dates, setBottle_release_dates] = useState()
  const [nftDetails, setNftDetails] = useState({})
  const [showBottom, setShowBottom] = useState(true)
  const [isActive, setActive] = useState(false)
  const [allcountryList, setAllCountryList] = useState([])
  const [allcountryListSearch, setAllCountryListSearch] = useState([])
  const [searchedText, setSearchedText] = useState([])
  const options = useMemo(() => countryList().getData(), [])
  const [openCountryModal, setOpenCountryModal] = useState(false)
  const [bottleDetails, setBottleDetail] = useState({})
  const [logsData, setLogs] = useState({})
  const [minDateEnd, setMinDateEnd] = useState({})

  const [isCalender, setIsCalender] = useState(false)
  const [countyBeforeOpen, setCountryBeforeOpen] = useState([])
  const [minReleaseDate, setMinReleaseDate] = useState({})
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  const [timeZoneOptions] = useState([])

  const disableField =
    dropDetails?.status !== CREATE_NFT_STATUSES.DRAFT &&
    dropDetails?.status !== CREATE_NFT_STATUSES.EMPTY_DRAFT
  const disableFieldApproved =
    dropDetails?.status !== CREATE_NFT_STATUSES.APPROVED &&
    dropDetails?.status !== CREATE_NFT_STATUSES.EMPTY_APPROVED
  const isEnded =
    dropDetails?.status === CREATE_NFT_STATUSES.ENDED ||
    dropDetails?.status === CREATE_NFT_STATUSES.SOLD_OUT
  const dispatch = useDispatch()
  const [merchants, setMerchants] = useState([])

  const [isEmptyDrop, setIsEmptyDrop] = useState(false)

  useEffect(() => {
    getBottleDetails()
    getLogsData()
  }, [])

  useEffect(() => {
    if (dropDetails.updatedAt) {
      // set min date for start drop event
      let releaseMinDate = new Date(dropDetails?.bottle_release_date)
      let minReleaseMinDate = {
        year: releaseMinDate.getUTCFullYear(),
        day: releaseMinDate.getUTCDate(),
        month: releaseMinDate.getUTCMonth() + 1, //months from 1-12
      }
      setMinReleaseDate(minReleaseMinDate)
    }
  }, [dropDetails])

  const nftSchema = isEmptyDrop ? emptyWineNftSchema : wineNftSchema

  const nftFormik = useFormik({
    enableReinitialize: true,
    validationSchema: nftSchema,
    initialValues: {
      nameOfWine: nftDetails?.bottle_data?.creator_meta?.winery_name,
      cuvee: nftDetails?.bottle_data?.wine_name,
      cru: nftDetails?.bottle_data?.vineyard_name,
      classification: nftDetails?.bottle_data?.classification,
      grape: nftDetails?.bottle_data?.grape,
      appellation: bottleDetails?.appellation_obj?.appellation,
      region: bottleDetails?.region_obj?.region_name,
      vintage: nftDetails?.vintage,
      no_of_bottles: nftDetails?.no_of_bottles,
      no_of_nft: nftDetails?.no_of_nft,
      price: nftDetails?.price,
      max_buy_limit: nftDetails?.max_buy_limit,
      timezone: nftDetails?.timezone,
      royality_payment_profilt: nftDetails?.royality_payment_profilt,
      start_drop_event: start_drop_event_date,
      finish_drop_event: finish_drop_event_date,
      bottle_release_date: bottle_release_dates,
      is_visible_count: nftDetails?.is_visible_count,
      is_exclusive_list: nftDetails?.is_exclusive_list,
      abv: nftDetails?.abv,
      price_ex_vat: nftDetails?.price_ex_vat,
      nft_name: nftDetails?.nft_name,
      nft_subtitle: nftDetails?.nft_subtitle,
      winery_perks: nftDetails?.winery_perks,
      drop_mode: nftDetails?.drop_mode,
      is_featured: nftDetails?.is_featured,
      fixed_exchange_rate_eur: nftDetails?.fixed_exchange_rate_eur,
      isRarible: nftDetails?.isRarible,
      merchant_type: nftDetails?.merchant_type,
      is_empty: nftDetails?.is_empty,
      commission_primary: nftDetails?.commission_primary,
      commission_secondary: nftDetails?.commission_secondary,
      cellar_price: nftDetails?.cellar_price,
      avg_price_comparison: nftDetails?.avg_price_comparison,
    },
    onSubmit: () => {
      const formValues = getCollectedFormData()

      if (formValues.shipping_location.length || isEmptyDrop) {
        saveEditNftDetails(formValues)
      } else {
        toast.error('Please choose at least one country')
      }
    },
  })

  const getCollectedFormData = () => {
    let formValues = { ...nftFormik.values }

    if (
      nftFormik.isValid &&
      nftFormik.values?.start_drop_event &&
      nftFormik.values?.finish_drop_event
    ) {
      let releaseDate =
        nftFormik.values.bottle_release_date.year +
        '/' +
        nftFormik.values.bottle_release_date.month +
        '/' +
        nftFormik.values.bottle_release_date.day

      let start_drop_event =
        nftFormik.values.start_drop_event.year +
        '/' +
        nftFormik.values.start_drop_event.month +
        '/' +
        nftFormik.values.start_drop_event.day +
        ' ' +
        nftFormik.values.start_drop_event.hour +
        ':' +
        nftFormik.values.start_drop_event.minute

      let finish_drop_event =
        nftFormik.values.finish_drop_event.year +
        '/' +
        nftFormik.values.finish_drop_event.month +
        '/' +
        nftFormik.values.finish_drop_event.day +
        ' ' +
        nftFormik.values.finish_drop_event.hour +
        ':' +
        nftFormik.values.finish_drop_event.minute

      let start = new Date(start_drop_event)
      let end = new Date(finish_drop_event)

      formValues.bottle_release_date = releaseDate
      formValues.start_drop_event = start.toISOString()
      formValues.finish_drop_event = end.toISOString()
      formValues.id = nftDetails.id

      formValues.fixed_exchange_rate_eur =
        formValues.fixed_exchange_rate_eur === '' ? 0 : formValues.fixed_exchange_rate_eur

      let country = [...allcountryList]
      let shipping_location = []

      country.forEach((element) => {
        if (element.isChecked) {
          shipping_location.push(element.label)
        }
      })

      delete formValues.nameOfWine
      // shipping_location

      formValues['shipping_location'] = shipping_location
    }

    return formValues
  }

  const saveEditNftDetails = (data) => {
    dispatch(updateNftDetails(data)).then((res) => {
      toast.success(res.data.message)
      saveMyDropDataLogs(data)
    })
  }

  const getLogsData = () => {
    let data = {
      id: dropDetails?.id,
      formType: 'dropData',
    }

    dispatch(getDropLogs(data)).then((res) => {
      let dropLogsData = res.data?.data?.drop
      setLogs(dropLogsData)
    })
  }

  const saveMyDropDataLogs = (newData) => {
    delete newData['id']
    let oldData = {
      bottle_release_date: nftDetails?.bottle_release_date,
      finish_drop_event: getDateFormatOld(nftDetails?.finish_drop_event),
      is_exclusive_list: nftDetails?.is_exclusive_list,
      is_visible_count: nftDetails?.is_visible_count,
      max_buy_limit: nftDetails?.max_buy_limit,
      no_of_bottles: nftDetails?.no_of_bottles,
      no_of_nft: nftDetails?.no_of_nft,
      price: nftDetails?.price,
      royality_payment_profilt: nftDetails?.royality_payment_profilt,
      shipping_location: nftDetails?.shipping_location,
      start_drop_event: getDateFormatOld(nftDetails?.start_drop_event),
      timezone: nftDetails?.timezone,
      vintage: nftDetails?.vintage,
      abv: nftDetails?.abv,
      price_ex_vat: nftDetails?.price_ex_vat,
      nft_name: nftDetails?.nft_name,
      nft_subtitle: nftDetails?.nft_subtitle,
      winery_perks: nftDetails?.nft_data?.winery_perks,
      drop_mode: nftDetails?.drop_mode,
      is_empty: nftDetails?.is_empty,
      merchant_type: nftDetails?.merchant_type,
      commission_primary: nftDetails?.commission_primary,
      commission_secondary: nftDetails?.commission_secondary,
      cellar_price: nftDetails?.cellar_price,
    }

    let finalData = {
      formType: 'dropData',
      prevData: oldData,
      newData: newData,
      id: nftDetails.id,
      userId: nftDetails.userId,
    }

    dispatch(saveDropLogs(finalData)).then((res) => {
      history.push('/panel/Dropevents')
    })
  }

  const getBottleDetails = () => {
    let bottleDetails = {
      id: dropDetails?.bottle_id,
    }
    dispatch(getBottleDetail(bottleDetails)).then((res) => {
      setBottleDetail(res.data.data)
      setMerchants(res.data.merchants)
    })
  }

  const getDateFormat = (date, time) => {
    let check = moment(date, 'YYYY/MM/DD hh:mm:ss')

    let finalDate = {}

    if (time) {
      let hours = date.getHours()
      let minutes = date.getMinutes()

      minutes = minutes < 10 ? `${minutes}` : minutes
      finalDate = {
        year: parseInt(check.format('YYYY')),
        month: parseInt(check.format('M')),
        day: parseInt(check.format('D')),
        hour: hours,
        minute: parseInt(minutes),
      }
    } else {
      finalDate = {
        year: parseInt(check.format('YYYY')),
        month: parseInt(check.format('M')),
        day: parseInt(check.format('D')),
      }
    }

    return finalDate
  }

  const getDateFormatOld = (date) => {
    let check = moment(date, 'YYYY/MM/DD hh:mm:ss')
    return (
      parseInt(check.format('YYYY')) +
      '-' +
      parseInt(check.format('M')) +
      '-' +
      parseInt(check.format('D')) +
      ' ' +
      parseInt(check.format('h')) +
      ':' +
      parseInt(check.format('m'))
    )
  }

  const getparticularNftDetails = () => {
    const data = {
      id: dropDetails?.id,
    }
    dispatch(getNftDetails(data)).then((res) => {
      setCountryToTop(res.data?.data?.shipping_location)
      setNftDetails(res.data.data)
      setIsEmptyDrop(res.data.data.is_empty)

      const start_drop_event = getDateFormat(new Date(res.data.data.start_drop_event), true)
      const finish_drop_event = getDateFormat(new Date(res.data.data.finish_drop_event), true)

      const setBottle_release_date = getDateFormat(res.data.data.bottle_release_date, false)
      setstart_drop_event(start_drop_event)

      setFinish_drop_event_date(finish_drop_event)
      setBottle_release_dates(setBottle_release_date)

      //save old data here
    })
  }

  useEffect(() => {
    const body = document.querySelector('body')
    document.body.classList.add('bg-white')
    return () => {
      body.classList.remove('bg-white')
    }
  })

  const getMinDate = () => {
    let addNineDays = new Date()

    addNineDays.setDate(addNineDays.getDate() + 2)

    let minDateEnd = {
      year: addNineDays.getUTCFullYear(),
      day: addNineDays.getUTCDate(),
      month: addNineDays.getUTCMonth() + 1, //months from 1-12
    }
    setMinDateEnd(minDateEnd)
  }

  useEffect(() => {
    getparticularNftDetails()
    getMinDate()
  }, [])

  const setParticularFieldValue = (e, field) => {
    nftFormik.setFieldValue(field, e.target.value)
  }

  const ToggleClass = () => {
    setActive(!isActive)
  }

  const onSelectCountry = (index) => {
    let country = [...allcountryList]
    country[index].isChecked = !country[index].isChecked
    setAllCountryList(country)
    setAllCountryListSearch(country)
  }

  const onSelectCountrySearch = (item) => {
    let countrySearch = [...allcountryListSearch]
    let country = [...allcountryList]

    countrySearch.forEach((element) => {
      if (element.label === item.label) {
        element.isChecked = !element.isChecked
      }
    })

    setAllCountryList(country)
    setAllCountryListSearch(countrySearch)
  }

  const onOpen = () => {
    let country = cloneDeep(allcountryList)
    setCountryBeforeOpen(country)
    setOpenCountryModal(true)
  }

  const onClose = () => {
    let c = [...countyBeforeOpen]
    setAllCountryList(c)

    setOpenCountryModal(false)
  }

  const onChangeSearchText = (value) => {
    setSearchedText(value)
    if (value === '') {
      setAllCountryListSearch([])
    } else {
      let searchCountry = [...allcountryList]
      let finalCountry = []

      searchCountry.forEach((element) => {
        if (element.label.toLowerCase().includes(value.toLowerCase())) {
          finalCountry.push(element)
        }
      })
      setAllCountryListSearch(finalCountry)
    }
  }

  const clearAllCountries = () => {
    let country = [...allcountryList]
    country.forEach((element) => {
      element.isChecked = false
    })
    setAllCountryList(country)
    setAllCountryListSearch(country)
    setSearchedText('')
  }

  const acceptSelectedCountries = () => {
    let country = [...allcountryList]
    let shipping_location = []

    country.forEach((element) => {
      if (element.isChecked) {
        shipping_location.push(element.label)
      }
    })

    if (shipping_location.length) {
      setOpenCountryModal(false)
      setSearchedText('')
    } else {
      toast.error('Please choose at least one country')
    }
  }

  const getTotalSelectedCount = () => {
    let country = [...allcountryList]

    let count = 0

    country.forEach((element) => {
      if (element.isChecked) {
        count = count + 1
      }
    })

    return count
  }
  const setCountryToTop = (country) => {
    // putting some selected country to top of list
    let allC = []

    options.forEach((element) => {
      // setting default selected country as per records
      if (country.includes(element.label)) {
        element.isChecked = true
      }

      if (
        element.label !== 'France' &&
        element.label !== 'Italy' &&
        element.label !== 'Spain' &&
        element.label !== 'United States' &&
        element.label !== 'Germany' &&
        element.label !== 'South Africa' &&
        element.label !== 'Australia'
      ) {
        allC.push(element)
      } else {
        allC.unshift(element)
      }
    })
    setAllCountryList(allC)
    setAllCountryListSearch(allC)
  }

  const sendNftToApprove = async () => {
    const errors = await nftFormik.validateForm()
    if (Object.keys(errors).length === 0) {
      // Form is valid, do any success call

      const formValues = getCollectedFormData()

      if (formValues.shipping_location.length || isEmptyDrop) {
        dispatch(updateNftDetails(formValues)).then((res) => {
          toast.success(res.data.message)
          saveMyDropDataLogs(formValues)

          let data = {
            id: nftDetails.id,
            status: CREATE_NFT_STATUSES.APPROVED,
          }
          dispatch(sendToApproveNft(data)).then(
            async (res) => {
              const dropName = getWineNameFromBottleDetails({
                bottleDetails,
                nft_name: nftFormik.values.nft_name,
              })
              await tagCustomEventSendPulse({
                eventLink: SENDPULSE_EVENTS.DROP_PUBLISHED,
                email: bottleDetails.email,
                customData: { drop_name: dropName },
              })
              toast.success(res.data.message)
              history.push('/panel/Dropevents')
            },
            (error) => {
              toast.error(error.data.message)
            },
          )
        })
      } else {
        toast.error('Please choose at least one country')
      }
    } else {
      nftFormik.setTouched(errors, true)
    }
  }

  const setAllSelected = () => {
    let country = [...allcountryList]
    country.forEach((element) => {
      element.isChecked = true
    })
    setAllCountryList(country)
    setAllCountryListSearch(country)
    setSearchedText('')
  }
  const handlePrice = (i) => {
    const temp400 = Number(i) + Number(i) * 0.2
    nftFormik.setFieldValue('price', temp400.toFixed(2))
  }
  const getCountrySelectedLabel = () => {
    const selectedCountriesN = getTotalSelectedCount()

    if (selectedCountriesN === allcountryList?.length) {
      return 'All countries selected'
    }
    if (selectedCountriesN === 1) {
      return `${selectedCountriesN} Country selected`
    }

    return `${selectedCountriesN} Countries selected`
  }
  return (
    <>
      <MainLayout>
        <div className='winemakers-main crtbtl EditWineft'>
          <div className={isActive ? 'brdr left' : 'left'}>
            <div className='logs-controller'>
              <div className='top'>
                <h3 className='common-sub-heading'>Logs</h3>

                <button
                  className={showBottom ? 'active logs-toggler' : 'logs-toggler'}
                  type='button'
                  onClick={() => {
                    setShowBottom(!showBottom)
                    ToggleClass()
                  }}
                ></button>
              </div>
              {showBottom && (
                <WineftLogs
                  data={logsData}
                  mainTitle='Is NFT count visible to user?'
                  oldValues='Bottleold'
                  newValues='BottleNew'
                />
              )}
            </div>
          </div>

          <div className='right'>
            <Row className='rightrow'>
              <Col xl={12} className='crtbtl-left'>
                <h1 className='common-heading HeadingSpace'>Edit WiNeFT</h1>

                <Form onSubmit={nftFormik.handleSubmit}>
                  <div className='block NftCreate' id='a'>
                    <InputField
                      name='Reserv price (USDC)'
                      label='Name of wine bottle'
                      placeholder=''
                      id='nameOfWine'
                      type='text'
                      value={nftFormik.values.nameOfWine}
                      disabled
                      formik={nftFormik}
                    />
                    <WineName
                      nftFormik={nftFormik}
                      disabled={disableField && disableFieldApproved}
                    />
                    <WineDescription
                      nftFormik={nftFormik}
                      disabled={disableField && disableFieldApproved}
                    />
                    <InputField
                      name='vintage'
                      label='Vintage'
                      placeholder='1999'
                      id='vintage'
                      type='text'
                      value={nftFormik.values.vintage}
                      onChange={(event) => {
                        nftFormik.setFieldValue('vintage', event.target.value)
                      }}
                      disabled={isEnded}
                      maxLength={100}
                    />
                    <InputField
                      name='Alcohol'
                      label='Alc.%'
                      placeholder='Alcohol %'
                      id='abv'
                      type='tel'
                      value={nftFormik.values.abv}
                      onChange={(event) => {
                        nftFormik.setFieldValue('abv', event.target.value)
                      }}
                      formik={nftFormik}
                      disabled={isEnded}
                    />
                    <InputField
                      isRequired
                      name='Bottles case'
                      label='Number of bottles in a case'
                      placeholder='Enter number 1 - 20'
                      id='no_of_bottles'
                      type='tel'
                      value={nftFormik.values.no_of_bottles}
                      onChange={(event) => {
                        nftFormik.setFieldValue('no_of_bottles', event.target.value)
                      }}
                      disabled={isEnded}
                      formik={nftFormik}
                    />
                    <div className='sectionInfo'>
                      <InputField
                        type='tel'
                        isRequired
                        name='WiNeFTs minted'
                        label='Number of WiNeFTs to be minted'
                        placeholder='Ex. 10'
                        id='no_of_nft'
                        value={nftFormik.values.no_of_nft}
                        onChange={(event) => {
                          nftFormik.setFieldValue('no_of_nft', event.target.value)
                        }}
                        formik={nftFormik}
                        disabled={disableField && disableFieldApproved}
                      />
                      <Popup
                        className='trigerEdit'
                        trigger={
                          <div className='InfoToggle'>
                            <img src={Info_Icon} alt='Info_Icon' />
                          </div>
                        }
                        content='This is the total # of digital NFTs that the winery wishes to launch on the platform.
                         For example, if Winery chooses to release 200 individual bottles to be sold on the platform,
                          the number of NFTs would be 200. Similarly, if the winery chooses to release 20 cases, 6 bottles
                           in each case, total # of WineFTs to be minted would be 20'
                        on={['hover', 'click']}
                        position='right center'
                      />
                    </div>
                    <div className='RadioBtn'>
                      <label className='drop_label radiolabel'>Is NFT count visible to user?</label>
                      <div className='RadioInfo'>
                        <RadioButton
                          label='Yes'
                          className='btnRadio'
                          id='nftCount1'
                          name='is_visible_count'
                          value={1}
                          checked={nftFormik.values.is_visible_count === 1}
                          onChange={(value) => nftFormik.setFieldValue('is_visible_count', value)}
                          disabled={isEnded}
                        />
                        <RadioButton
                          label='No'
                          className='btnRadio'
                          id='is_visible_count2'
                          name='is_visible_count'
                          value={0}
                          checked={nftFormik.values.is_visible_count === 0}
                          onChange={(value) => nftFormik.setFieldValue('is_visible_count', value)}
                          disabled={isEnded}
                        />
                        <Popup
                          className='trigerEdit'
                          trigger={
                            <div className='InfoToggle'>
                              <img src={Info_Icon} alt='Info_Icon' />
                            </div>
                          }
                          content='This will always default to “No”. Admin should confirm with WineChain leadership,
                           if for any reason Winery wants to provide visibility on volume of available NFTs, to end customer'
                          on={['hover', 'click']}
                          position='right center'
                        />
                      </div>
                    </div>
                    <div className='RadioBtn'>
                      <label className='drop_label radiolabel'>
                        Will the Drop event be specifically meant ONLY for Winery's exclusive access
                        list?
                      </label>
                      <div className='RadioInfo'>
                        <RadioButton
                          label='Yes'
                          className='btnRadio'
                          id='is_exclusive_list1'
                          name='is_exclusive_list'
                          value={1}
                          checked={parseInt(nftFormik.values.is_exclusive_list) === 1}
                          onChange={(value) => nftFormik.setFieldValue('is_exclusive_list', value)}
                          disabled={isEnded}
                        />
                        <RadioButton
                          label='No'
                          className='btnRadio'
                          id='is_exclusive_list2'
                          name='is_exclusive_list'
                          value={0}
                          checked={parseInt(nftFormik.values.is_exclusive_list) === 0}
                          onChange={(value) => nftFormik.setFieldValue('is_exclusive_list', value)}
                          disabled={isEnded}
                        />
                        <Popup
                          className='trigerEdit'
                          trigger={
                            <div className='InfoToggle'>
                              <img src={Info_Icon} alt='Info_Icon' />
                            </div>
                          }
                          content='selecting “Yes” would mean only very specific selected members who have exclusive access 
                          to the Winery can participate in the Drop event. Selecting “No” would imply any member in the platform 
                          can participate in the Drop event'
                          on={['hover', 'click']}
                          position='right center'
                        />
                      </div>
                    </div>
                    <div className='RadioBtn'>
                      <p>
                        <label className='drop_label radiolabel'>
                          <strong>Select Drop mode with two option - VAT ex and VAT incl</strong>
                        </label>
                      </p>
                      <div className='RadioInfo RadioInfoVat'>
                        {vatModes.map((mode) => (
                          <RadioButton
                            key={mode.value}
                            label={mode.label}
                            className='btnRadio'
                            id={mode.id}
                            name='drop_mode'
                            value={mode.value}
                            checked={nftFormik.values.drop_mode === mode.value}
                            onChange={(value) => nftFormik.setFieldValue('drop_mode', value)}
                            disabled={disableField && disableFieldApproved}
                          />
                        ))}
                      </div>
                    </div>
                    <InputField
                      name='Reserv price (USDC)'
                      label='Price ex VAT (USDC)'
                      placeholder='Ex. 300'
                      isRequired
                      id='price_ex_vat'
                      type='tel'
                      min='0'
                      value={nftFormik.values.price_ex_vat}
                      onChange={(event) => {
                        if (event.target.value.length < 10) {
                          if (event.target.value === '0' || event.target.value === '') {
                            nftFormik.setFieldValue('price_ex_vat', '')
                            handlePrice('0')
                          } else {
                            nftFormik.setFieldValue('price_ex_vat', event.target.value)
                            handlePrice(event.target.value)
                          }
                        }
                      }}
                      formik={nftFormik}
                      disabled={disableField && disableFieldApproved}
                    />
                    <InputField
                      name='Fixed Exchange Rate (EUR)'
                      label='Fixed Exchange Rate (EUR)'
                      placeholder='Ex. 0.855'
                      id='fixed_exchange_rate_eur'
                      type='tel'
                      min='0'
                      value={nftFormik.values.fixed_exchange_rate_eur}
                      onChange={(event) => {
                        if (event.target.value.length < 10) {
                          nftFormik.setFieldValue('fixed_exchange_rate_eur', event.target.value)
                        }
                      }}
                      disabled={isEnded}
                    />
                    <InputField
                      name='Insert avg market price comparison'
                      label='Insert avg market price comparison'
                      placeholder='Ex. $20 or 20%'
                      id='avg_price_comparison'
                      value={nftFormik.values.avg_price_comparison}
                      onChange={(event) => {
                        if (event.target.value.length < 10) {
                          nftFormik.setFieldValue('avg_price_comparison', event.target.value)
                        }
                      }}
                      disabled={isEnded}
                    />
                    {nftFormik.values.drop_mode === 'VAT incl' && (
                      <div className='createFinalPrice'>
                        <p className='createFinalPrice__title'>
                          Final Price for the customer including VAT
                        </p>
                        <p className='createFinalPrice__text'>
                          {nftFormik.values.price ? nftFormik.values.price : '0'} USDC
                        </p>
                      </div>
                    )}
                    <div className='InputPresent'>
                      <InputField
                        type='tel'
                        min='0'
                        className='InfoInput'
                        label='Winechain primary fee 0-100%'
                        placeholder='Ex. 20%'
                        id='commission_primary'
                        value={nftFormik.values.commission_primary}
                        onChange={(event) => {
                          if (
                            (event.target.value >= 0 && event.target.value <= 100) ||
                            event.target.value === ''
                          ) {
                            nftFormik.setFieldValue('commission_primary', event.target.value)
                          }
                        }}
                        formik={nftFormik}
                        disabled={isEnded}
                      />
                      <label className='Sublabel'>%</label>
                    </div>
                    <div className='InputPresent'>
                      <InputField
                        type='tel'
                        min='0'
                        className='InfoInput'
                        label='Winechain secondary fee 0-100%'
                        placeholder='Ex. 20%'
                        id='commission_secondary'
                        value={nftFormik.values.commission_secondary}
                        onChange={(event) => {
                          if (
                            (event.target.value >= 0 && event.target.value <= 100) ||
                            event.target.value === ''
                          ) {
                            nftFormik.setFieldValue('commission_secondary', event.target.value)
                          }
                        }}
                        formik={nftFormik}
                        disabled={isEnded}
                      />
                      <label className='Sublabel'>%</label>
                    </div>
                    <div className='sectionInfo'>
                      <InputField
                        isRequired
                        type={'tel'}
                        name='NFTs one Wallet'
                        label='Max. number of NFTs one Wallet can buy ?'
                        placeholder='Ex. 1'
                        id='max_buy_limit'
                        value={nftFormik.values.max_buy_limit}
                        onChange={(event) => {
                          nftFormik.setFieldValue('max_buy_limit', event.target.value)
                        }}
                        formik={nftFormik}
                        disabled={isEnded}
                      />
                      <Popup
                        className='trigerEdit'
                        trigger={
                          <div className='InfoToggle'>
                            <img src={Info_Icon} alt='Info_Icon' />
                          </div>
                        }
                        content='Winery can choose to provide a restriction on the number of NFTs that a particular 
                        user/member can be allowed to purchase. Please enter a number between 1 -100'
                        on={['hover', 'click']}
                        position='right center'
                      />
                    </div>
                    <div className='cus_drop inputField__wrap perameter_Inputs'>
                      <label className='drop_label'>Time Zone</label>
                      <select
                        id='timezone'
                        value={nftFormik.values.timezone}
                        onChange={(e) => setParticularFieldValue(e, 'timezone')}
                        disabled={isEnded}
                      >
                        <option value='' disabled>
                          {timeZone}
                        </option>
                        {TIME_ZONES.map((item) => (
                          <option
                            key={item.name}
                            value={item.offset}
                            label={`${item.name} - ${item.offset}`}
                          >
                            {timeZoneOptions}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='sectionInfo'>
                      <div className='perameter_Inputs'>
                        <CalendarDateTime
                          isRequired
                          id='bottle_release_date'
                          label='Bottle release date (date the physical wine bottle will be available)'
                          value={nftFormik.values.bottle_release_date}
                          placeholder='Ex. 13 May 2022'
                          disabled={isEnded}
                          initValue={bottle_release_dates}
                          onChangeHandler={(date) => {
                            if (date !== null) {
                              nftFormik.setFieldValue('bottle_release_date', date)
                            } else {
                              nftFormik.setFieldValue('bottle_release_date', '')
                            }
                          }}
                          withTime={false}
                          minDate={minReleaseDate}
                          isDisabled={isEnded}
                        />
                        {nftFormik.touched.bottle_release_date && (
                          <p
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              marginRight: 'auto',
                              marginLeft: '0',
                            }}
                          >
                            {nftFormik.errors.bottle_release_date}
                          </p>
                        )}
                      </div>
                      <Popup
                        className='trigerEdit'
                        trigger={
                          <div className='InfoToggle'>
                            <img src={Info_Icon} alt='Info_Icon' />
                          </div>
                        }
                        content='please change this to “Date the physical wine bottle will be produced and 
                        available to be sold on the platform, i.e., bottle release date” Add Tooltip: Release 
                        date can be in the future. A Winery can choose to create NFT of a bottle that is going 
                        to be released in the future, as long as they can guarantee that such a bottle will be 
                        released in the market'
                        on={['hover', 'click']}
                        position='right center'
                      />
                    </div>
                    <div className='sectionInfo'>
                      <div className='cus_drop perameter_Inputs'>
                        <label className='drop_label'>
                          Winery specific geographical restrictions, for shipping only
                        </label>

                        <button
                          disabled={isEnded}
                          type='button'
                          className='DropCalender'
                          onClick={() => {
                            if (dropDetails.type !== 'CURRENT') {
                              onOpen()
                            }
                          }}
                        >
                          {getCountrySelectedLabel()}
                        </button>
                      </div>
                      <Popup
                        className='trigerEdit'
                        trigger={
                          <div className='InfoToggle'>
                            <img src={Info_Icon} alt='Info_Icon' />
                          </div>
                        }
                        content="Winery may have its own restrictions of not shipping to certain parts of the world.
                        These are on top of the global shipping restrictions that a winery might be legally obligated
                        to follow. Note that, restrictions only apply to Shipping. A customer can be located in the
                        specific geographical restricted area and still purchase the WineFT, he just won't be able to
                        ship it to himself"
                        on={['hover', 'click']}
                        position='right center'
                      />
                    </div>
                    <div className='sectionInfo'>
                      <div className='InputPresent'>
                        <InputField
                          type='tel'
                          className='InfoInput'
                          label='Royalty payments for Secondary sale, on profits from sale'
                          placeholder='Ex. 20% as like default'
                          id='royality_payment_profilt'
                          value={nftFormik.values.royality_payment_profilt?.toString()}
                          onChange={(event) => {
                            if (event.target.value >= 0 && event.target.value <= 90) {
                              nftFormik.setFieldValue(
                                'royality_payment_profilt',
                                event.target.value,
                              )
                            }
                          }}
                          disabled={isEnded}
                          formik={nftFormik}
                        />
                        <p className='Sublabel'>%</p>
                      </div>
                      <Popup
                        className='trigerEdit'
                        trigger={
                          <div className='InfoToggle'>
                            <img src={Info_Icon} alt='Info_Icon' />
                          </div>
                        }
                        content={
                          <>
                            <div className='Listing'>
                              <p>
                                Royalties are based on the profits due to resale of wine in the
                                secondary market. The WineChain platform takes 10% of your profits
                                from resale and the winery also takes a % of profits. For details on
                                Winery’s share of profits, please see the key bottle details in the
                                Drop events page.
                              </p>
                              <h6>Here’s an example:</h6>
                              <ul className='popupList'>
                                <li>Purchase price of bottle: 100 USDC</li>
                                <li>Resale price for bottle on WineChain Marketplace: 150 USDC</li>
                                <li>Profit = 50 USDC</li>
                                <li>
                                  Winery’s share (presuming 20% royalty share): 20% * 50 USDC = 10
                                  USDC
                                </li>
                                <li>WineChain Platform’s share: 10% * 50 USDC = 5 USDC</li>
                                <li>Profit for the end user: 50 - 10 -5 USDC = 35 USDC</li>
                                <li>
                                  Final revenue for the end user from secondary sale: 185 USDC”
                                </li>
                              </ul>
                            </div>
                          </>
                        }
                        on={['hover', 'click']}
                        position='right center'
                      />
                    </div>
                    <div className='sectionInfo'>
                      <div className='perameter_Inputs'>
                        <CalendarDateTime
                          label='Date of Start Drop Event'
                          isRequired
                          placeholder='Ex. 13 May 2022, 12h 00m'
                          initValue={nftFormik.values.start_drop_event}
                          value={nftFormik.values.start_drop_event}
                          onChangeHandler={(date) => {
                            nftFormik.setFieldValue('start_drop_event', date)
                          }}
                          withTime={true}
                          onCalenderShow={() => setIsCalender(true)}
                          onCalenderHide={() => setIsCalender(false)}
                          isDisabled={disableField && disableFieldApproved}
                        />
                        {nftFormik.touched.start_drop_event && (
                          <p
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              marginRight: 'auto',
                              marginLeft: '0',
                            }}
                          >
                            {nftFormik.errors.start_drop_event}
                          </p>
                        )}
                      </div>
                      <Popup
                        className='trigerEdit'
                        trigger={
                          <div className='InfoToggle'>
                            <img src={Info_Icon} alt='Info_Icon' />
                          </div>
                        }
                        content='Winechain should be in full control of the exact date of release. Winery 
                        can suggest their preferred week for drop event'
                        on={['hover', 'click']}
                        position='right center'
                      />
                    </div>
                    <div className='perameter_Inputs'>
                      <CalendarDateTime
                        label='Date and time Finish drop event'
                        isRequired
                        placeholder='Ex. 13 May 2022, 12h 00m'
                        withTime={true}
                        value={nftFormik.values.finish_drop_event}
                        initValue={nftFormik.values.finish_drop_event}
                        onChangeHandler={(date) => {
                          if (date) {
                            nftFormik.setFieldValue('finish_drop_event', date)
                          } else {
                            setFinish_drop_event_date('')
                            nftFormik.setFieldValue('finish_drop_event', '')
                          }
                        }}
                        minDate={minDateEnd}
                        onCalenderShow={() => setIsCalender(true)}
                        onCalenderHide={() => setIsCalender(false)}
                        isDisabled={disableField && disableFieldApproved}
                      />
                      {nftFormik.touched.finish_drop_event && (
                        <p
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            marginRight: 'auto',
                            marginLeft: '0',
                          }}
                        >
                          {nftFormik.errors.finish_drop_event}
                        </p>
                      )}
                    </div>
                    <div className='perameter_Inputs'>
                      <InputField
                        type='textArea'
                        name='winery_perks'
                        label='Winery Perks'
                        placeholder='Winery Perks...'
                        id='winery_perks'
                        value={nftFormik.values.winery_perks}
                        onChange={(event) => {
                          nftFormik.setFieldValue('winery_perks', event.target.value)
                        }}
                        disabled={isEnded}
                        formik={nftFormik}
                      />
                    </div>
                    <div className='field checkboxBlock'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          className=''
                          name='is_featured'
                          type='checkbox'
                          checked={nftFormik.values.is_featured}
                          value={nftFormik.values.is_featured}
                          onChange={(e) => {
                            nftFormik.setFieldValue('is_featured', e.target.checked ? 1 : 0)
                          }}
                          disabled={isEnded}
                        />
                        <label>Featured</label>
                      </div>
                      <p className='infoData'>
                        Note: If selected then it will display on marketplace page
                      </p>
                    </div>
                    <div className='field checkboxBlock'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          className=''
                          name='is_empty'
                          type='checkbox'
                          checked={nftFormik.values.is_empty}
                          value={nftFormik.values.is_empty}
                          onChange={(e) => {
                            nftFormik.setFieldValue('is_empty', e.target.checked ? 1 : 0)
                            setIsEmptyDrop(e.target.checked ? true : false)
                          }}
                          disabled={disableField && disableFieldApproved}
                        />
                        <label>Empty</label>
                      </div>
                      <p className='infoData'>
                        Note: If selected then it will display on marketplace page
                      </p>
                    </div>
                    <div className='cus_drop inputField__wrap perameter_Inputs'>
                      <label className='inputField__label'>Merchant type</label>
                      <select
                        showSearch
                        id='merchant_type'
                        value={nftFormik.values.merchant_type}
                        onChange={(e) => {
                          nftFormik.setFieldValue('merchant_type', Number(e.target.value))
                          if (Number(e.target.value) === 1) {
                            nftFormik.setFieldValue('cellar_price', '')
                          }
                        }}
                        disabled={disableField && disableFieldApproved}
                      >
                        {merchants?.map((item) => (
                          <option
                            key={item.merchant_id}
                            value={item.merchant_id}
                            label={item.merchant_name}
                          />
                        ))}
                      </select>
                    </div>
                    {nftFormik.values.merchant_type !== 1 && (
                      <InputField
                        type='tel'
                        min='0'
                        className='InfoInput'
                        label='Cellar Price'
                        placeholder='Ex. 300'
                        id='cellar_price'
                        value={nftFormik.values.cellar_price}
                        onChange={(event) => {
                          if (event.target.value >= 0 || event.target.value === '') {
                            nftFormik.setFieldValue('cellar_price', event.target.value)
                          }
                        }}
                        formik={nftFormik}
                        disabled={disableField && disableFieldApproved}
                      />
                    )}
                    <div className='field checkboxBlock'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          className=''
                          name='isRarible'
                          type='checkbox'
                          checked={nftFormik.values.isRarible}
                          value={nftFormik.values.isRarible}
                          onChange={(e) => {
                            nftFormik.setFieldValue('isRarible', e.target.checked ? 1 : 0)
                          }}
                          disabled={disableField && disableFieldApproved}
                        />
                        <label className='inputField__label'>Rarible</label>
                      </div>
                      <p className='infoData'>Note: This will enable the drop only for Rarible</p>
                    </div>
                  </div>
                  <div className='BtnIn_Edit'>
                    <div className='BtnListing'>
                      <ButtonCustom
                        type='button'
                        onClick={() => history.push('/panel/Dropevents')}
                        title='Cancel'
                        customClass='borderBtn addNew_Btn Listspace'
                      />
                      <ButtonCustom
                        type='submit'
                        title='Save'
                        customClass='saveBtn2'
                        disabled={isCalender || isEnded}
                      />
                    </div>
                    {!disableField && (
                      <div className='ApprovedBtn'>
                        <ButtonCustom
                          type='button'
                          title='Save and Approve'
                          customClass='SendBtn'
                          onClick={sendNftToApprove}
                        />
                      </div>
                    )}
                  </div>
                </Form>
              </Col>
              <Col xl={12} className='crtbtl-right'>
                <div className='inner'>
                  <div className='Header'>
                    <h3 className='common-sub-heading'>Token card Preview</h3>
                  </div>
                  <CardIframe
                    data={{
                      ...dropDetails,
                      ...nftDetails,
                      ...{
                        ...nftFormik.values,
                        start_drop_event: nftDetails.start_drop_event,
                        finish_drop_event: nftDetails.finish_drop_event,
                      },
                      users: { creator_meta: { country_code: bottleDetails.countrycode } },
                      bottle_data: {
                        winery_name: nftDetails?.bottle_data?.creator_meta?.winery_name,
                        ...bottleDetails,
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
            <CustomDropdown
              option={searchedText === '' ? allcountryList : allcountryListSearch}
              onSelect={onSelectCountry}
              onSelectSearchedCountry={onSelectCountrySearch}
              searchedText={searchedText}
              onSearch={onChangeSearchText}
              onClose={onClose}
              onOpen={onOpen}
              open={openCountryModal}
              clearAllCountries={clearAllCountries}
              onAccept={acceptSelectedCountries}
              getTotalSectected={getTotalSelectedCount}
              setAllSelected={setAllSelected}
            />
          </div>
        </div>
      </MainLayout>
    </>
  )
}

export default EditWineft
